function valueEncode(value, isGlobalIndex = false) {
  let sanitizedValue
  if (isGlobalIndex) {
    const reservedChars = /[+\-&|!(){}[\]"~?:\\]/g
    sanitizedValue = (value || "").replace(reservedChars, "\\$&")
  } else {
    sanitizedValue = value || ""
  }
  return encodeURIComponent(sanitizedValue)
}

function queryStringify(obj, sepString, eqString, defaultOpts, isGlobalIndex = false) {
  let opts = defaultOpts || {}

  if (typeof opts == "function") opts = { cmp: opts }

  const sep = sepString || "&"
  const eq = eqString || "="

  let encode = (value) => valueEncode(value, isGlobalIndex)

  if (typeof opts.encodeURIComponent == "function") {
    encode = opts.encodeURIComponent
  }

  if (obj !== null && typeof obj == "object") {
    const keys = objectKeys(obj)
    const len = keys.length
    const flast = len - 1
    let fields = ""

    keys.sort(opts.cmp && keyComparator(obj, opts.cmp))

    for (let i = 0; i < len; ++i) {
      const k = keys[i]
      const v = obj[k]
      const ks = encode(stringifyPrimitive(k)) + eq

      if (isArray(v)) {
        v.sort(opts.cmp && valComparator(k, opts.cmp))

        const vlen = v.length
        const vlast = vlen - 1
        for (let j = 0; j < vlen; ++j) {
          fields += ks + encode(stringifyPrimitive(v[j]))
          if (j < vlast) fields += sep
        }
        if (vlen && i < flast) fields += sep
      } else {
        fields += ks + encode(stringifyPrimitive(v))
        if (i < flast) fields += sep
      }
    }
    return fields
  }
  return ""
}

function keyComparator(v, cmp) {
  return function (a, b) {
    const aobj = { key: a, value: v[a] }
    const bobj = { key: b, value: v[b] }
    return cmp(aobj, bobj)
  }
}

function valComparator(k, cmp) {
  return function (a, b) {
    const aobj = { key: k, value: a }
    const bobj = { key: k, value: b }
    return cmp(aobj, bobj)
  }
}

function stringifyPrimitive(v) {
  if (typeof v == "string") return v
  if (typeof v == "number" && isFinite(v)) return "" + v
  if (typeof v == "boolean") return v ? "true" : "false"
  return ""
}

const isArray =
  Array.isArray ||
  function (x) {
    return {}.toString.call(x) === "[object Array]"
  }

const objectKeys =
  Object.keys ||
  function (obj) {
    const has =
      Object.prototype.hasOwnProperty ||
      function () {
        return true
      }
    const keys = []
    for (const key in obj) {
      if (has.call(obj, key)) keys.push(key)
    }
    return keys
  }

export default queryStringify
