import { Suspense } from "react"

import { GlobalActionsDispatcher, StoreProvider } from "@ggs/store"
import { MuiThemeProvider } from "@ggs/styles"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { HeadProvider } from "react-head"
import SiteMetadataProvider from "./SiteMetadataProvider"

const queryClient = new QueryClient()

/**
 * @typedef {Object} RootProvidersProps
 * @property {JSX.Element} children
 */

/**
 * Composition of providers dependencies.
 * @param {RootProvidersProps} props
 * @return {JSX.Element}
 */
export default function RootProviders({ children }) {
  //const isSSR = typeof window === "undefined"
  const headTags = []
  return (
    <HeadProvider headTags={headTags}>
      <StoreProvider>
        <GlobalActionsDispatcher>
          <MuiThemeProvider>
            <SiteMetadataProvider>
              <QueryClientProvider client={queryClient}>
                <Suspense fallback="loading">{children}</Suspense>
                {/* {children} */}
              </QueryClientProvider>
            </SiteMetadataProvider>
          </MuiThemeProvider>
        </GlobalActionsDispatcher>
      </StoreProvider>
    </HeadProvider>
  )
}
