exports.components = {
  "component---src-components-page-wrappers-city-tsx": () => import("./../../../src/components/PageWrappers/City.tsx" /* webpackChunkName: "component---src-components-page-wrappers-city-tsx" */),
  "component---src-components-page-wrappers-landmark-tsx": () => import("./../../../src/components/PageWrappers/Landmark.tsx" /* webpackChunkName: "component---src-components-page-wrappers-landmark-tsx" */),
  "component---src-components-page-wrappers-places-to-go-tsx": () => import("./../../../src/components/PageWrappers/PlacesToGo.tsx" /* webpackChunkName: "component---src-components-page-wrappers-places-to-go-tsx" */),
  "component---src-components-page-wrappers-region-tsx": () => import("./../../../src/components/PageWrappers/Region.tsx" /* webpackChunkName: "component---src-components-page-wrappers-region-tsx" */),
  "component---src-components-page-wrappers-state-tsx": () => import("./../../../src/components/PageWrappers/State.tsx" /* webpackChunkName: "component---src-components-page-wrappers-state-tsx" */),
  "component---src-components-page-wrappers-travel-style-tsx": () => import("./../../../src/components/PageWrappers/TravelStyle.tsx" /* webpackChunkName: "component---src-components-page-wrappers-travel-style-tsx" */),
  "component---src-components-page-wrappers-ways-to-travel-tsx": () => import("./../../../src/components/PageWrappers/WaysToTravel.tsx" /* webpackChunkName: "component---src-components-page-wrappers-ways-to-travel-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-all-trips-view-tsx": () => import("./../../../src/templates/all-trips-view.tsx" /* webpackChunkName: "component---src-templates-all-trips-view-tsx" */),
  "component---src-templates-article-category-view-tsx": () => import("./../../../src/templates/articleCategory-view.tsx" /* webpackChunkName: "component---src-templates-article-category-view-tsx" */),
  "component---src-templates-article-type-view-tsx": () => import("./../../../src/templates/article-type-view.tsx" /* webpackChunkName: "component---src-templates-article-type-view-tsx" */),
  "component---src-templates-article-view-tsx": () => import("./../../../src/templates/article-view.tsx" /* webpackChunkName: "component---src-templates-article-view-tsx" */),
  "component---src-templates-author-view-tsx": () => import("./../../../src/templates/author-view.tsx" /* webpackChunkName: "component---src-templates-author-view-tsx" */),
  "component---src-templates-country-view-tsx": () => import("./../../../src/templates/country-view.tsx" /* webpackChunkName: "component---src-templates-country-view-tsx" */),
  "component---src-templates-destination-view-tsx": () => import("./../../../src/templates/destination-view.tsx" /* webpackChunkName: "component---src-templates-destination-view-tsx" */),
  "component---src-templates-landing-page-view-tsx": () => import("./../../../src/templates/landingPage-view.tsx" /* webpackChunkName: "component---src-templates-landing-page-view-tsx" */),
  "component---src-templates-sitemap-view-tsx": () => import("./../../../src/templates/sitemap-view.tsx" /* webpackChunkName: "component---src-templates-sitemap-view-tsx" */),
  "component---src-templates-staff-department-view-tsx": () => import("./../../../src/templates/staffDepartment-view.tsx" /* webpackChunkName: "component---src-templates-staff-department-view-tsx" */),
  "component---src-templates-staff-profile-view-tsx": () => import("./../../../src/templates/staffProfile-view.tsx" /* webpackChunkName: "component---src-templates-staff-profile-view-tsx" */),
  "component---src-templates-staff-type-view-tsx": () => import("./../../../src/templates/staffType-view.tsx" /* webpackChunkName: "component---src-templates-staff-type-view-tsx" */),
  "component---src-templates-subpage-travel-guides-view-tsx": () => import("./../../../src/templates/subpage-travel-guides-view.tsx" /* webpackChunkName: "component---src-templates-subpage-travel-guides-view-tsx" */),
  "component---src-templates-subpage-trip-itineraries-view-tsx": () => import("./../../../src/templates/subpage-trip-itineraries-view.tsx" /* webpackChunkName: "component---src-templates-subpage-trip-itineraries-view-tsx" */),
  "component---src-templates-travel-guide-view-tsx": () => import("./../../../src/templates/travel-guide-view.tsx" /* webpackChunkName: "component---src-templates-travel-guide-view-tsx" */),
  "component---src-templates-trip-detail-view-tsx": () => import("./../../../src/templates/trip-detail-view.tsx" /* webpackChunkName: "component---src-templates-trip-detail-view-tsx" */),
  "component---src-templates-wishlist-view-tsx": () => import("./../../../src/templates/wishlist-view.tsx" /* webpackChunkName: "component---src-templates-wishlist-view-tsx" */),
  "slice---src-components-article-type-jsx": () => import("./../../../src/components/ArticleType.jsx" /* webpackChunkName: "slice---src-components-article-type-jsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/Footer/Footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/Header/Header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */),
  "slice---src-components-pre-footer-pre-footer-style-1-tsx": () => import("./../../../src/components/PreFooter/PreFooterStyle1.tsx" /* webpackChunkName: "slice---src-components-pre-footer-pre-footer-style-1-tsx" */),
  "slice---src-components-pre-footer-pre-footer-style-2-tsx": () => import("./../../../src/components/PreFooter/PreFooterStyle2.tsx" /* webpackChunkName: "slice---src-components-pre-footer-pre-footer-style-2-tsx" */),
  "slice---src-components-pre-footer-pre-footer-style-3-tsx": () => import("./../../../src/components/PreFooter/PreFooterStyle3.tsx" /* webpackChunkName: "slice---src-components-pre-footer-pre-footer-style-3-tsx" */),
  "slice---src-components-pre-footer-pre-footer-style-4-tsx": () => import("./../../../src/components/PreFooter/PreFooterStyle4.tsx" /* webpackChunkName: "slice---src-components-pre-footer-pre-footer-style-4-tsx" */),
  "slice---src-components-pre-footer-pre-footer-style-5-tsx": () => import("./../../../src/components/PreFooter/PreFooterStyle5.tsx" /* webpackChunkName: "slice---src-components-pre-footer-pre-footer-style-5-tsx" */),
  "slice---src-components-pre-footer-pre-footer-style-6-tsx": () => import("./../../../src/components/PreFooter/PreFooterStyle6.tsx" /* webpackChunkName: "slice---src-components-pre-footer-pre-footer-style-6-tsx" */),
  "slice---src-components-pre-footer-pre-footer-style-7-tsx": () => import("./../../../src/components/PreFooter/PreFooterStyle7.tsx" /* webpackChunkName: "slice---src-components-pre-footer-pre-footer-style-7-tsx" */)
}

