import dayjs from "dayjs"
import { find, first, get, isArray, isEmpty, merge, sortBy, upperFirst } from "lodash"

import { useSelector } from "@ggs/store"
import { numberFormatter } from "@ggs/utils"
import bodyTrim from "../../helpers/body-trim"
import useUserLocation from "./useUserLocation"

/**
 * @typedef {import("../../definitions").BaseAd} BaseAd
 * @typedef {import("../../definitions").RunOfSiteAd} RunOfSiteAd
 * @typedef {import("../../definitions").TripLevelAd} TripLevelAd
 * @typedef {import("../../definitions").AuthorArticle} AuthorArticle
 * @typedef {import("../../definitions").TrendingArticles} TrendingArticles
 * @typedef {import("../../components/GlobalAdBanners/Types").AdBanner} AdBanner
 * @typedef {import("../../components/LocationBanners/LocationBanners").BannerItem} LocationBanner
 */

/**
 * @typedef {import("../../definitions").SlideshowItemType} SlideshowItemType
 * @typedef {import("../../definitions").BaseStaffProfile} BaseStaffProfile
 */

/**
 * @typedef {import("../../definitions").TeaserGridItemProps} SlideshowItemType
 * @typedef {import("../../definitions").BaseStaffProfile} BaseStaffProfile
 */

/**
 * @typedef {import("../../definitions").Article} ArticleTeaser
 */

/**
 * @typedef {(results: BaseAd[], type: string) => RunOfSiteAd} GlobalSiteAdIndexFilter
 * @typedef {(ads: RunOfSiteAd) => any} RunOfSiteAdsIndexToBanner
 */

/**
 * @typedef {Object} UseDataObjectTransformHook
 * @property {GlobalSiteAdIndexFilter} globalSiteAdIndexFilter
 * @property {RunOfSiteAdsIndexToBanner} runOfSiteAdIndexToAdBanner
 */

/**
 * @typedef {import("../../definitions").CountryDestination} CountryDestination
 */

/**
 * @typedef {import("../../components/BlogSubNavigation/Types").NavigationItemProps} NavigationItem
 * @typedef {import("../../definitions").ArticleCategory} ArticleCategory
 */

/**
 * @typedef {import("../../components/TeaserCard/TeaserCard").TeaserCardProps} TravelGuideItem
 * @typedef {import("../../definitions").TravelGuide} TravelGuide
 */

/**
 * @typedef {import("../../components/TripTeaser/TripTeaser").TripTeaserProps} TripItem
 * @typedef {import("../../definitions").Trip} Trip
 */

const useDataObjectTransform = () => {
  const userCountry = useSelector((state) => state.settings.country)
  const { mapCountryToCurrency, mapSaveAmountToCurrency } = useUserLocation()
  const currencyCode = mapCountryToCurrency(userCountry?.code) || ""
  const saveAmountField = mapSaveAmountToCurrency(userCountry?.code) || ""
  const saveAmountFieldOverride = mapSaveAmountToCurrency(userCountry?.code, false, true) || ""
  const priceField = `its_package_price_${currencyCode.toLocaleLowerCase()}`
  const overridePriceField = `package_price_${currencyCode.toLocaleLowerCase()}`

  /**
   * Filter global site ad index result objects by ad type:
   *  - run_of_site_ad
   *  - trip_level_ad
   * @type {GlobalSiteAdIndexFilter}
   * @param {BaseAd[]} results Global site index results array
   * @param {string} type Ad type to filter
   * @returns {RunOfSiteAd}
   */
  const globalSiteAdIndexFilter = (results, type) => {
    if (!isArray(results)) {
      return null
    }

    return results.filter((item) => {
      const componentType = first(item?.sm_components_type)
      return componentType === type
    })
  }

  /**
   * Filter staff result objects by current profile:
   * @param {BaseStaffProfile[]} results Global site index results array
   * @param current_profile_id
   * @returns {SlideshowItemType[]}
   */
  const staffIndexFilterAndTransform = (results, current_profile_id = null) => {
    if (!isArray(results)) {
      return null
    }
    const filteredResults = current_profile_id
      ? results.filter((item) => {
          return item?.its_nid[0] !== current_profile_id
        })
      : results
    if (filteredResults.length > 0) {
      return filteredResults?.map((item) => {
        const imageStylesJson = first(item?.zs_photo_image_style_obj) || "{}"
        const imageStylesArray = JSON.parse(imageStylesJson) || {}
        const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray

        const resolve = {
          title: item.ss_title?.[0],
          subtitle: item.ss_position?.[0],
          uri: item.ss_url?.[0],
          link: {
            title: item.ss_title?.[0],
            uri: item.ss_url?.[0],
          },
        }
        if (imageStylesArray.length > 0) {
          resolve.image = {
            variants: [
              ...imageStylesArray,
              {
                style: "default",
                url:
                  find(imageStyles, "profile_overview_image_3xl").profile_overview_image_3xl || "",
              },
            ],
          }
        }
        return resolve
      })
    }
    return null
  }

  /**
   * Transform staff users to match Slideshow component data model:
   * @param {BaseStaffProfile[]} results Global site index results array
   * @returns {SlideshowItemType[]}
   */
  const staffIndexTransformLandingPage = (results) => {
    if (!isArray(results)) {
      return null
    }
    return results?.map((item) => {
      const imageStylesJson = first(item?.zs_photo_image_style_obj) || "{}"
      const imageStylesArray = JSON.parse(imageStylesJson) || {}
      const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray

      const resolve = {
        title: item.ss_title_s,
        link: {
          title: item.ss_title_s,
          uri: item.ss_url?.[0],
        },
      }

      if (imageStylesArray.length > 0) {
        resolve.image = {
          variants: [
            ...imageStylesArray,
            {
              style: "default",
              url: find(imageStyles, "profile_overview_image_3xl").profile_overview_image_3xl || "",
            },
          ],
        }
      }

      return resolve
    })
  }

  /**
   * Filter staff result objects by current profile:
   * @param {BaseStaffProfile[]} results Global site index results array
   * @returns {DestinationsGridProps[]}
   */
  const destinationFilterAndTransform = (results) => {
    if (!isArray(results)) {
      return null
    }
    if (results.length > 0) {
      return results.map((item) => {
        const imageStylesJson = first(item?.zs_hero_image_style_obj) || "{}"
        const imageStylesArray = JSON.parse(imageStylesJson) || {}
        const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray
        const resolve = {
          link: {
            title: item.ss_title?.[0],
            uri: item?.ss_url?.[0],
          },
        }
        if (imageStylesArray.length > 0) {
          resolve.image = {
            variants: [
              ...imageStylesArray,
              {
                style: "default",
                url:
                  find(imageStyles, "staff_type_image_overview_image_3xl")
                    .staff_type_image_overview_image_3xl || "",
                alt: item?.sm_hero_images_alt,
              },
            ],
          }
        }
        return resolve
      })
    }
    return null
  }

  /**
   * Filter staff result objects by current profile:
   * @param {BaseStaffProfile[]} results Global site index results array
   * @returns {DestinationsGridProps[]}
   */
  const staffIndexTransformFilter = (results) => {
    if (!isArray(results)) {
      return null
    }

    if (results.length > 0) {
      return results.map((item) => {
        const imageStylesJson = get(item, "zs_photo_image_style_obj")
        const imageStylesArray = !isEmpty(imageStylesJson) ? JSON.parse(imageStylesJson) : {}
        const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray
        const resolve = {
          name: item?.ss_title,
          location: item?.sm_destinations_title.join(", "),
          bio: first(item?.ss_bio),
          uri: first(item?.ss_url),
        }

        if (!isEmpty(imageStyles)) {
          resolve.image = {
            variants: [
              ...imageStyles,
              {
                style: "default",
                url: find(imageStyles, "staff_profile_photo_3xl")?.staff_profile_photo_3xl,
              },
            ],
          }
        }
        return resolve
      })
    }
    return null
  }

  /**
   * Transform Run of Site Ad results into add banner items.
   * @type {RunOfSiteAdsIndexToBanner}
   * @param {RunOfSiteAd[]} ads
   * @return {AdBanner[]}
   */
  const runOfSiteAdsIndexToAdBanner = (ads) => {
    if (!isArray(ads)) {
      return null
    }

    return ads.map((item) => {
      const imageStylesJson = first(item?.sm_components_image_image_style_obj) || "{}"
      const imageStylesObject = JSON.parse(imageStylesJson) || {}
      const imageStyles = merge({}, ...imageStylesObject)

      return {
        title: item.sm_components_title,
        description: item.sm_components_description,
        link: {
          label: item.sm_components_link_title,
          url: item.sm_components_link_url,
        },
        imageUrl: get(imageStyles, "run_of_site_ad_3xl") || "",
      }
    })
  }

  const fieldCheck = (field) => {
    if (isArray(field) && field.length > 0) return field[0]
  }

  /**
   * General mapping of destination fields to map items objects.
   *
   * @param {CountryDestination[]} destinations
   * @param {string} typeField
   */
  const mapDestinationFields = (destinations, typeField) => {
    return destinations.map((destination) => {
      const imageStylesJson = first(destination?.zs_hero_image_style_obj) || "{}"
      const imageStylesArray = JSON.parse(imageStylesJson) || {}
      const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray

      const resolve = {
        label: fieldCheck(destination?.ss_title),
        description: fieldCheck(destination?.ss_facts_overview_description),
        lat: fieldCheck(destination?.fts_lat),
        lng: fieldCheck(destination?.fts_lng),
        uri: fieldCheck(destination?.ss_url),
        type: upperFirst(fieldCheck(get(destination, typeField, ""))),
      }

      if (imageStylesArray.length > 0) {
        resolve.image = {
          variants: [
            ...imageStylesArray,
            {
              style: "default",
              url: find(imageStyles, "hero_s1_3xl").hero_s1_3xl || "",
            },
          ],
        }
      }

      return resolve
    })
  }

  /**
   * Map throught destinations array to create object for Map component
   *
   * @param {CountryDestination[]} results
   */
  const geoEntitiesDataTransform = (results) => {
    if (!isArray(results)) {
      return null
    }

    if (results.length > 0) {
      // filter results that are missing either lat and lng
      const filteredDestinations = results.filter((item) => item.fts_lat && item.fts_lng)
      return mapDestinationFields(filteredDestinations, "ss_type")
    }
  }

  /**
   * Map throught destinations array to create object for Map component
   *
   * @param {CountryDestination[]} results
   */
  const destinationDataTransform = (results) => {
    if (!isArray(results)) {
      return null
    }

    if (results.length > 0) {
      // filter results that are missing either lat and lng
      const filteredDestinations = results.filter((item) => item.fts_lat && item.fts_lng)
      return mapDestinationFields(filteredDestinations, "ss_destination_region_title")
    }
  }

  const countryByDestinationTransform = (destinations) => {
    if (Array.isArray(destinations) && destinations?.length > 0) {
      // sort destinations alphabetically by title
      const sortedDestinations = sortBy(destinations, "ss_title[0]")
      return sortedDestinations.map((destination) => {
        const imageStylesJson = first(destination?.zs_hero_image_style_obj) || "{}"
        const imageStylesArray = JSON.parse(imageStylesJson) || {}
        const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray

        const resolve = {
          title: destination?.ss_title?.[0],
          link: destination?.ss_url?.[0],
        }

        if (imageStylesArray.length > 0) {
          resolve.image = {
            variants: [
              ...imageStylesArray,
              {
                style: "default",
                url: find(imageStyles, "hero_s1_3xl").hero_s1_3xl || "",
              },
            ],
          }
        }

        return resolve
      })
    }
    return null
  }

  /**
   * Transform Run of Site Ad results into add banner items.
   * @type {RunOfSiteAdsIndexToBanner}
   * @param {TripLevelAd[]} ads
   * @return {LocationBanner[]}
   */
  const locationAdsIndexToLocationBanner = (ads) => {
    if (!isArray(ads)) {
      return null
    }

    return ads.map((item) => {
      const imageStylesJson = first(item?.sm_components_image_image_style_obj) || "{}"
      const imageStylesObject = JSON.parse(imageStylesJson) || {}
      const imageStyles = merge({}, ...imageStylesObject)
      const imageVariants = []
      const image3xl = {
        url: get(imageStyles, "run_of_site_ad_3xl", null),
        title: first(item.sm_components_title),
        style: "3xl",
        alt: "",
        height: null,
        width: null,
      }

      if (image3xl.url) {
        imageVariants.push(image3xl)
      }

      const countryName = get(item, "sm_country_title[0]", null)
      const destinationName = get(item, "sm_destinations_title[0]", null)
      const locationName =
        countryName && destinationName ? `${countryName} - ${destinationName}` : null

      return {
        title: first(item.sm_components_title),
        titleLogoUrl: first(item.sm_components_icon_url),
        description: first(item.sm_components_description),
        link: {
          title: first(item.sm_components_link_title) || "",
          uri: first(item.sm_components_link_url) || "#",
        },
        location: locationName,
        image: {
          variants: imageVariants,
        },
      }
    })
  }

  /**
   * Transform Article results into article teasers items.
   * @type {RunOfSiteAdsIndexToBanner}
   * @param {ArticleTeaser[]} articles
   */
  const articleTeaserTransform = (articles) => {
    if (!isArray(articles)) {
      return []
    }

    return articles.map((article) => {
      const resolve = {
        article_type: {
          title: get(article, "sm_article_category_title[0]", ""),
          uri: get(article, "sm_article_category_path[0]", "#"),
        },
        title: article.ss_title,
        description: bodyTrim(article.ss_body?.[0], 20),
        uri: article.ss_url?.[0],
        nid: article.its_nid,
        contentType: first(article.ss_type) || "",
      }

      const imageStylesJson = first(article.zm_featured_images_image_style_obj) || "{}"
      const imageStylesArray = JSON.parse(imageStylesJson) || {}
      const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray

      resolve.image = {
        variants: [
          {
            style: "default",
            url:
              find(imageStyles, "article_featured_image_teaser")?.article_featured_image_teaser ||
              "",
            alt: article?.sm_featured_images_alt || "",
          },
        ],
      }

      return resolve
    })
  }

  const articleOverrideTransform = (articles) => {
    if (!isArray(articles)) {
      return []
    }

    return articles.map((item) => ({
      ...item,
      image: item?.featured_images?.[0],
      uri: item?.slug,
    }))
  }

  const getBookDate = (specialOfferScheduler) => {
    if (specialOfferScheduler) {
      const isDateExpired = dayjs().isAfter(dayjs(specialOfferScheduler))
      return isDateExpired ? null : dayjs(specialOfferScheduler).format("MMM DD, YYYY")
    }
  }

  const tripOverrideTransform = (trips) => {
    if (!isArray(trips)) {
      return []
    }

    return trips.map((trip) => {
      const countryCategories = [
        {
          label: trip?.primary_country?.title,
          uri: trip?.primary_country?.slug,
        },
      ]
      const priceValue = trip?.package_code?.[overridePriceField] || ""
      return {
        ...trip,
        image: trip?.hero_image,
        uri: trip?.self_link?.uri,
        bookDate: getBookDate(trip?.special_offer_scheduler),
        duration: trip?.package_code?.package_duration || -1,
        price: numberFormatter.currency(priceValue, numberFormatter.CURRENCIES[currencyCode]),
        rawPrice: priceValue,
        savings: resolveTripOverrideSavings(trip),
        addedValue: trip?.added_value,
        isSpecial: trip?.special_offer,
        ...(countryCategories.length > 0
          ? {
              locationTags: countryCategories,
            }
          : {}),
      }
    })
  }

  /**
   * Transform Run of Site Ad results into add banner items.
   * @param {SubPage[]} items
   * @return {links[{title, uri}}]}
   */
  const subPagesIndexToDynamicSubPageItems = (items) => {
    if (!isArray(items)) {
      return null
    }

    const mapPageLabel = (type) => {
      switch (type) {
        case "places_to_go":
          return "Places To Go"
        case "trip_itineraries":
          return "Trip Itineraries"
        case "travel_guides":
          return "Travel Guides"
        default:
          return null
      }
    }

    const transformed = items.map((item) => {
      return {
        title: mapPageLabel(item?.ss_template_type?.[0]) || "",
        uri: item.ss_url?.[0],
      }
    })
    return transformed
  }

  /**
   * Transform author articles for Author Articles Collection.
   * @param {AuthorArticle[]} articles
   * or Trending articles.
   * @param {TrendingArticles[]} articles
   * @param {[key: string]: string} authorfLink
   */

  const authorArticlesCollectionDataFilterAndTransform = (articles, authorfLink, liked) => {
    if (!isArray(articles)) {
      return null
    }

    return articles?.map((article) => {
      const imageStylesJson = first(article.zm_featured_images_image_style_obj) || "{}"
      const imageStylesArray = JSON.parse(imageStylesJson) || {}
      const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray
      const image = {
        variants: [
          {
            style: "default",
            url:
              find(imageStyles, "article_featured_image_teaser").article_featured_image_teaser ||
              "",
          },
        ],
      }
      return {
        image,
        title: first(article.ss_title) || "",
        author:
          {
            title:
              authorfLink?.title || article.ss_authored_by_slug
                ? article.ss_authored_by_slug[0]
                : "",
            uri:
              authorfLink?.uri || article.ss_authored_by_path ? article.ss_authored_by_path[0] : "",
          } || {},
        uri: first(article.ss_url) || "",
        date: dayjs(article.timestamp[0]).format("MMM DD, YYYY"),
        tags:
          article.sm_article_category_title?.length > 0
            ? article.sm_article_category_title?.map((category, index) => {
                return {
                  label: category,
                  color: "floralWhite",
                  uri: article.sm_article_category_path?.[index],
                }
              })
            : {},
        contentType: first(article.ss_type) || "",
        nid: first(article.its_nid),
        ...(liked ? { liked } : { liked: false }),
      }
    })
  }

  /** Transform Article category results into blog sub navigation items.
   * @param {ArticleCategory[]} categories
   * @return {NavigationItem[]}
   */
  const articleCategoryTransform = (categories) => {
    if (!isArray(categories)) {
      return null
    }

    return categories?.map((item) => ({
      title: item?.ss_title?.[0],
      uri: item?.ss_url?.[0],
      icon: item?.ss_icon_option?.[0],
      id: item?.its_nid[0],
    }))
  }

  /**
   * @param {TravelGuide[]} travelGuides
   * @return {TravelGuideItem[]}
   */
  const travelGuideFilterAndTransform = (travelGuides, excludedTravelGuideId) => {
    if (!isArray(travelGuides)) return null

    const filteredTravelGuides = travelGuides?.filter((guide) => {
      return guide?.its_nid?.[0] !== excludedTravelGuideId
    })

    if (isArray(filteredTravelGuides) && filteredTravelGuides?.length) {
      return filteredTravelGuides?.map((guide) => {
        const imageStylesJson = first(guide?.zs_hero_image_image_style_obj) || "{}"
        const imageStylesArray = JSON.parse(imageStylesJson) || {}
        const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray

        const resolve = {
          title: guide?.ss_title?.[0],
          description: guide?.ss_body?.[0],
          link: { title: "Read Guide", uri: guide?.ss_url?.[0] },
          icon: "guide",
        }

        if (imageStylesArray.length > 0) {
          resolve.image = {
            variants: [
              ...imageStylesArray,
              {
                style: "default",
                url: find(imageStyles, "hero_s1_3xl").hero_s1_3xl || "",
              },
            ],
          }
        }
        return resolve
      })
    }
    return null
  }

  /**
   * @param {TravelGuide[]} travelGuides
   * @return {TravelGuideItem[]}
   */
  const nextTravelGuideTransform = (travelGuides) => {
    if (isArray(travelGuides) && travelGuides?.length) {
      const guide = travelGuides[0]
      const imageStylesJson = first(guide?.zs_hero_image_image_style_obj) || "{}"
      const imageStylesArray = JSON.parse(imageStylesJson) || {}
      const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray

      const resolve = {
        title: guide?.ss_title?.[0],
        uri: guide?.ss_url?.[0],
      }
      if (imageStylesArray.length > 0) {
        resolve.image = {
          variants: [
            ...imageStylesArray,
            {
              style: "default",
              url: find(imageStyles, "explore_list_item_3xl").explore_list_item_3xl || "",
            },
          ],
        }
      }
      return resolve
    }
    return null
  }

  /**
   * Transform articles type for Press Centre List.
   * @param {AuthorArticle[]} articles
   */

  const articleTypeDataTransform = (articles) => {
    return articles.map((article) => {
      const image = {
        variants:
          article.zm_featured_images_image_style_obj?.length > 0
            ? Object.values(JSON.parse(article.zm_featured_images_image_style_obj[0])).map(
                (image) => {
                  const imageUrl = Object.values(image)
                  return {
                    url: imageUrl[0],
                    title: imageUrl[0],
                    alt: imageUrl[0],
                    style: "default",
                  }
                }
              )
            : {},
      }
      return {
        title: first(article.ss_title),
        uri: first(article.ss_url),
        date: dayjs(article.timestamp[0]).format("MMM DD, YYYY"),
        image,
      }
    })
  }

  /**
   * @param {TravelGuide[]} travelGuides
   * @return {TravelGuideItem[]}
   */
  const wishlistGuideGuideTransform = (travelGuides) => {
    if (!isArray(travelGuides)) return null
    return travelGuides?.map((guide) => {
      const imageStylesJson = first(guide?.zs_hero_image_image_style_obj) || "{}"
      const imageStylesArray = JSON.parse(imageStylesJson) || {}
      const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray

      const resolve = {
        title: guide?.ss_title?.[0],
        description: guide?.ss_body?.[0],
        link: { title: "Read Guide", uri: guide?.ss_url?.[0] },
        icon: "guide",
        liked: true,
      }

      if (imageStylesArray.length > 0) {
        resolve.image = {
          variants: [
            ...imageStylesArray,
            {
              style: "default",
              url: find(imageStyles, "explore_list_item_3xl").explore_list_item_3xl || "",
            },
          ],
        }
      }
      return resolve
    })
  }

  /**
   * Resolve trip savings for a trip.
   * @param {*} trip
   * @param {string} countryCode
   * @returns {string}
   */
  const resolveTripSavings = (trip) => {
    const saveValue =
      trip[saveAmountField] && trip[saveAmountField][0] ? trip[saveAmountField][0] : ""
    const saveAmount = saveValue
      ? numberFormatter.currency(saveValue, numberFormatter.CURRENCIES[currencyCode])
      : ""
    const savePercent =
      trip.its_save_percent && trip.its_save_percent[0] ? `${trip.its_save_percent[0]}%` : ""

    return saveAmount || savePercent || ""
  }

  const resolveTripOverrideSavings = (trip) => {
    const saveValue =
      trip[saveAmountFieldOverride] && trip[saveAmountFieldOverride]
        ? trip[saveAmountFieldOverride]
        : ""
    const saveAmount = saveValue
      ? numberFormatter.currency(saveValue, numberFormatter.CURRENCIES[currencyCode])
      : ""
    const savePercent = trip.save_percent ? `${trip.save_percent}%` : ""

    return saveAmount || savePercent || ""
  }

  /**
   * Transform Suggested Trips data.
   * @param {SuggestedTrips[]} trips
   */
  const tripDataFilterAndTransform = (trips, liked) => {
    if (!isArray(trips)) return null
    return trips?.map((trip) => {
      const imageStylesJson = first(trip?.zs_hero_image_fid_image_styles) || "{}"
      const imageStylesArray = JSON.parse(imageStylesJson) || {}
      const imageStyles = isEmpty(imageStylesJson) ? {} : imageStylesArray

      const image = isEmpty(imageStylesJson)
        ? { variants: [] }
        : {
            variants: [
              {
                style: "default",
                url: find(imageStyles, "hero_s1_teaser_20_23_3xl")?.hero_s1_teaser_20_23_3xl || "",
              },
            ],
          }

      const categoryTagsArr = []
      /**Create path.
       * @param {string[]} category
       * @param {string} pathName
       */
      const pathHandler = (category, pathName) => {
        category.forEach((item) => {
          const path = item.replaceAll(" ", "-").replace("&", "and").toLowerCase()
          categoryTagsArr.push({
            label: item || "",
            uri: pathName + path,
          })
        })
      }
      trip.ss_ways_to_travel_title && pathHandler(trip.ss_ways_to_travel_title, "/experiences/")
      trip.ss_travel_style_title && pathHandler(trip.ss_travel_style_title, "/travel-style/")

      const countryCategory = [
        {
          label: trip.ss_primary_country_country_title?.[0],
          uri: trip.ss_primary_country_country_path?.[0],
        },
      ]

      trip.sm_country_title?.forEach((country, index) => {
        if (country !== trip.ss_primary_country_country_title?.[0]) {
          countryCategory.push({ label: country, uri: trip.sm_country_path?.[index] })
        }
      })

      const priceValue = trip?.[priceField]?.[0] || ""
      return {
        title: first(trip.ss_title) || "",
        uri: first(trip.ss_url) || "",
        bookDate: getBookDate(trip?.ds_special_offer_scheduler?.[0]),
        duration: first(trip.its_duration) || 0,
        price: numberFormatter.currency(priceValue, numberFormatter.CURRENCIES[currencyCode]),
        rawPrice: priceValue,
        savings: resolveTripSavings(trip),
        addedValue: first(trip.bs_added_value) || null,
        isSpecial: first(trip.bs_special_offer) || null,
        image,
        nid: first(trip.its_nid) || "",
        contentType: first(trip.ss_type) || "",
        ...(liked && { liked }),
        ...(trip.ss_price_band
          ? {
              pricingTag: {
                label: first(trip.ss_price_band) || "",
                uri: "#",
              },
            }
          : {}),
        ...(countryCategory.length > 0
          ? {
              locationTags: countryCategory,
            }
          : {}),
        ...(categoryTagsArr
          ? {
              categoryTags: categoryTagsArr,
            }
          : {}),
      }
    })
  }

  /**
   * @param {Trip[]} trips
   * @return {TripItem[]}
   */
  const tripSearchFilterAndTransform = (trips) => {
    if (!isArray(trips)) return null

    // title: string
    // image: ResponsiveImageType
    // duration: string
    // price: string
    // uri: string
    // bookDate?: string
    // savings?: string
    // pricingTag?: TagProps
    // locationTags?: TagProps[]
    // categoryTags?: TagProps[]
    // liked?: boolean

    return trips.map((trip) => {
      const imageStylesJson = first(trip.zs_hero_image_fid_image_styles) || "[]"

      const imageStyles = JSON.parse(imageStylesJson).map((item) => ({
        style: Object.entries(item)[0][0],
        url: Object.entries(item)[0][1],
        alt: trip?.ss_hero_image_alternative_text ? trip?.ss_hero_image_alternative_text[0] : "",
      }))

      const defaultImage = {
        style: "default",
        url: trip.sm_hero_image_url
          ? trip.sm_hero_image_url[0]
          : imageStyles.find((item) => item.style === "hero_s1_3xl")?.url ?? "",
        alt: trip?.ss_hero_image_alternative_text ? trip?.ss_hero_image_alternative_text[0] : "",
      }

      const priceValue = trip?.[priceField]?.[0] || ""
      return {
        title: trip.ss_title[0],
        nid: trip.its_nid[0],
        contentType: trip.ss_type[0],
        image: {
          variants: [...imageStyles, defaultImage],
        },
        duration: `${trip.its_duration ? trip.its_duration[0] : -1}`,
        uri: trip.ss_url[0],
        price: numberFormatter.currency(priceValue, numberFormatter.CURRENCIES[currencyCode]),
        rawPrice: priceValue,
        bookDate: getBookDate(trip?.ds_special_offer_scheduler?.[0]),
        savings: resolveTripSavings(trip),
        addedValue: first(trip.bs_added_value) || null,
        isSpecial: first(trip.bs_special_offer) || null,
        pricingTag: {
          label: trip.ss_price_band?.[0],
        },
        // TODO: How are the links for location tags built? Search page with location param?
        locationTags: [
          ...(trip?.ss_primary_country_country_title
            ? [
                {
                  label: trip?.ss_primary_country_country_title?.[0],
                  uri: trip?.ss_primary_country_country_path?.[0],
                },
              ]
            : []),
          ...(trip.sm_country_title?.map((country, i) => ({
            label: country,
            uri: trip?.sm_country_path?.[i],
          })) ?? []),
        ],
        // TODO: How are the links for category tags built? Search page with category param?
        categoryTags: [
          ...(trip.ss_travel_style_title?.map((style) => ({
            label: style,
            uri: `?style=${encodeURIComponent(style).replace(/%26/g, "and")}`,
          })) ?? []),
          ...(trip.ss_ways_to_travel_title?.map((way) => ({
            label: way,
            uri: `?way=${encodeURIComponent(way).replace(/%26/g, "and")}`,
          })) ?? []),
        ],
      }
    })
  }

  /**
   * @param {Trip[]} trips
   * @return {TripItem[]}
   */
  const tripSearchFilterAndTransformByCountry = (trips) => {
    if (!isArray(trips)) return null

    return trips.map((trip) => {
      const imageStylesJson = first(trip.zs_hero_image_fid_image_styles) || "[]"

      const imageStyles = JSON.parse(imageStylesJson).map((item) => ({
        style: Object.entries(item)[0][0],
        url: Object.entries(item)[0][1],
        alt: trip?.ss_hero_image_alternative_text ? trip?.ss_hero_image_alternative_text[0] : "",
      }))

      const defaultImage = {
        style: "default",
        url: trip.sm_hero_image_url
          ? trip.sm_hero_image_url[0]
          : imageStyles.find((item) => item.style === "hero_s1_3xl")?.url ?? "",
        alt: trip?.ss_hero_image_alternative_text ? trip?.ss_hero_image_alternative_text[0] : "",
      }

      const priceValue = trip?.[priceField]?.[0] || ""
      return {
        title: trip.ss_title[0],
        nid: trip.its_nid[0],
        contentType: trip.ss_type[0],
        image: {
          variants: [...imageStyles, defaultImage],
        },
        duration: `${trip.its_duration ? trip.its_duration[0] : -1}`,
        uri: trip.ss_url[0],
        price: numberFormatter.currency(priceValue, numberFormatter.CURRENCIES[currencyCode]),
        rawPrice: priceValue,
        addedValue: first(trip.bs_added_value) || null,
        isSpecial: first(trip.bs_special_offer) || null,
        bookDate: getBookDate(trip?.ds_special_offer_scheduler?.[0]),
        savings: resolveTripSavings(trip),
        pricingTag: {
          label: trip.ss_price_band?.[0],
        },
        // TODO: How are the links for location tags built? Search page with location param?
        locationTags: [
          ...(trip?.ss_primary_country_country_title
            ? [
                {
                  label: trip?.ss_primary_country_country_title?.[0],
                  uri: trip?.ss_primary_country_country_path?.[0],
                },
              ]
            : []),
          ...(trip.sm_country_title?.map((country, i) => ({
            label: country,
            uri: trip?.sm_country_path?.[i],
          })) ?? []),
          ...(trip.ss_gw_geo_pages_keyvalue?.map((location) => ({
            label: location.split(":")[1],
            uri: `?location=${encodeURIComponent(location.split(":")[0])}`,
          })) ?? []),
        ],
        // TODO: How are the links for category tags built? Search page with category param?
        categoryTags: [
          ...(trip.ss_travel_style_title?.map((style) => ({
            label: style,
            uri: `?style=${encodeURIComponent(style).replace(/%26/g, "and")}`,
          })) ?? []),
          ...(trip.ss_ways_to_travel_title?.map((way) => ({
            label: way,
            uri: `?way=${encodeURIComponent(way).replace(/%26/g, "and")}`,
          })) ?? []),
        ],
        // TODO: How is liked determined?
      }
    })
  }

  /**
   * @param {(string | number)[]} facets
   * @return {{ label: string, value: string }[]}
   */
  const tripSearchFacetLabelValueTransform = (facets, type = "") => {
    if (!isArray(facets)) return []
    return facets
      .filter((_, i) => i % 2 === 0)
      .map((facet, i) => {
        const facetParts = facet.split(":")
        const [value, label] = facetParts
        return {
          label: label || value,
          value: value,
          ...(type && { experience: type }),
        }
      })
      .sort((a, b) => a.label?.localeCompare(b.label))
  }

  /**
   * Transform Search data.
   * @param {GlobalSearchResults[]} data
   */

  const globalSearchDataFilterAndtransform = (data) => {
    if (!isArray(data)) return null
    return data.map((item) => {
      let teaserImage
      if (item.zm_node_featured_images_fid_image_styles)
        teaserImage = item.zm_node_featured_images_fid_image_styles
      if (item.zs_node_photo_fid_image_styles) teaserImage = item.zs_node_photo_fid_image_styles
      if (item.zm_node_hero_fid_image_styles) teaserImage = item.zm_node_hero_fid_image_styles
      if (item.zs_node_hero_image_fid_image_styles)
        teaserImage = item.zs_node_hero_image_fid_image_styles
      if (item.zs_node_featured_images_fid_image_styles)
        teaserImage = item.zs_node_featured_images_fid_image_styles

      const image = {
        variants: teaserImage
          ? Object.values(JSON.parse(teaserImage[0])).map((image) => {
              const imageUrl = Object.values(image)
              return {
                url: imageUrl[0],
                title: imageUrl[0],
                alt: imageUrl[0],
                style: "default",
              }
            })
          : {},
      }

      const description =
        first(item.tm_X3b_en_node_body) ||
        first(item.tm_X3b_en_node_description) ||
        first(item.ss_content_highlight_description) ||
        first(item.tm_X3b_en_node_overview_description) ||
        ""
      const cleanDescription = description.replace(/(<([^>]+)>)/gi, "") || ""

      return {
        title: first(item.tcngramstringm_X3b_en_node_title) || "",
        description: cleanDescription,
        image: image || {},
        uri: first(item.ss_url) || "",
        type: first(item.ss_node_type) || "",
      }
    })
  }

  const autocompleteDataTransform = (data) => {
    if (!isArray(data)) return []
    return data.map((item) => {
      return {
        title: first(item.tcngramstringm_X3b_en_node_title, ""),
        uri: first(item.ss_url, null),
      }
    })
  }

  const articleSearchFilterAndTransform = (articles) => {
    if (!isArray(articles)) return null

    return articles.map((article) => {
      const imageStylesJson = first(article.zs_featured_images_image_style_obj) || "[]"

      const imageStyles = JSON.parse(imageStylesJson).map((item) => ({
        style: Object.entries(item)[0][0],
        url: Object.entries(item)[0][1],
        alt: article?.sm_featured_images_alt || "",
      }))

      // Search results display in a list view so we only neead teaser image.
      const teaserVariant = find(imageStyles, { style: "article_featured_image_teaser" })
      const defaultImage = teaserVariant
        ? [
            {
              ...teaserVariant,
              style: "default",
              alt: article?.sm_featured_images_alt ? article?.sm_featured_images_alt[0] : "",
            },
          ]
        : []

      return {
        title: article.ss_title[0],
        image: {
          variants: defaultImage,
        },
        article_type: {
          title: article.sm_article_category_title?.[0],
          uri: article.sm_article_category_path?.[0],
        },
        date: dayjs(article.ds_created?.[0]).format("MMM DD, YYYY"),
        uri: article.ss_url?.[0],
        contentType: article.ss_type?.[0],
        nid: article.its_nid?.[0],
        author: {
          title: article.ss_authored_by_slug?.[0],
          uri: article.ss_authored_by_path?.[0],
        },
        tags: [
          {
            label: article.sm_article_category_title?.[0],
            uri: article.sm_article_category_path?.[0],
          },
        ],
      }
    })
  }

  const globalSearchFiltersTransform = (filters, totalCount) => {
    if (!isArray(filters)) return null
    const order = ["all", "article", "trip", "travel_guide", "landing_page", "destination"]
    const newArray = [{ label: "Show all", value: "all", count: totalCount }]
    for (let i = 0; i < filters.length; i += 2) {
      const label = filters[i]
      const count = filters[i + 1]

      // Modify the label to the desired format (e.g., capitalize, or replace underscores)
      const formattedLabel = label
        .replace(/_/g, " ") // Replace underscores with spaces
        .replace(/\b\w/g, (l) => l.toUpperCase()) // Capitalize each word

      // Add the new object to the newArray
      newArray.push({ label: formattedLabel, value: label, count: count })
    }
    // return the array sorted in the same order every time
    return newArray.sort((a, b) => order.indexOf(a.value) - order.indexOf(b.value))
  }

  return {
    globalSiteAdIndexFilter,
    runOfSiteAdsIndexToAdBanner,
    staffIndexFilterAndTransform,
    destinationFilterAndTransform,
    staffIndexTransformFilter,
    destinationDataTransform,
    staffIndexTransformLandingPage,
    locationAdsIndexToLocationBanner,
    articleTeaserTransform,
    authorArticlesCollectionDataFilterAndTransform,
    subPagesIndexToDynamicSubPageItems,
    articleCategoryTransform,
    travelGuideFilterAndTransform,
    nextTravelGuideTransform,
    articleTypeDataTransform,
    tripDataFilterAndTransform,
    countryByDestinationTransform,
    wishlistGuideGuideTransform,
    geoEntitiesDataTransform,
    globalSearchDataFilterAndtransform,
    tripSearchFilterAndTransform,
    tripSearchFacetLabelValueTransform,
    tripSearchFilterAndTransformByCountry,
    articleSearchFilterAndTransform,
    articleOverrideTransform,
    tripOverrideTransform,
    autocompleteDataTransform,
    globalSearchFiltersTransform,
  }
}

export default useDataObjectTransform
