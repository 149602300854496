import { Provider } from "react-redux"
//import { PersistGate } from "redux-persist/integration/react"
import { store } from "./store"

/**
 * @typedef {Object} StoreProviderProps
 * @property {JSX.Element} children
 */

/**
 *
 * @param {StoreProviderProps} props
 * @return {JSX.Element}
 */
export function StoreProvider({ children }) {
  return (
    <Provider store={store}>
      {/* <PersistGate persistor={persistor}>{children}</PersistGate> */}
      {children}
    </Provider>
  )
}
